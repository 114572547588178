<template>
  <v-row class="mt-16">
    <v-col class="mb-4">
      <h1 class="display-1 font-weight-bold mx-1 mb-3">Authenticating</h1>
    </v-col>
  </v-row>
</template>

<script>
import OAuth from '@/api/oauth'
// import Accounting from '@/api/accounting'
export default {
  components: {},
  data () {
    return {}
  },
  async created () {
    const redirectUrl = this.$store.getters.currentRedirect
    OAuth.setToken(this.$route.fullPath).then((result) => {
      this.$store.dispatch('setCurrentRedirect', '/')
      this.$router.push(redirectUrl)
    })
  },
  computed: {},
  watch: {},
  methods: {
  }
}
</script>
